.login-background {
    background-image: url('../img/background.png');
    background-size: cover;
    width: 100%;
    height: 100%;
}

.login-card {
    display: flex !important;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.avatar {
    background-image: url("../../../../layouts/header/assets/img/avatar.png");
    background-size: cover;
    /* width: 55px;
    height: 55px; */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-position: top center;
}