.home .ant-col {
    display: inline-flex;
    align-self: stretch;    



}

.home-card {
    border: 1px solid #F4F4F5;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 40px 40px 20px 20px;
    width: 100%;
}

.home-card ul {
    list-style-type: disc;
}

.home-card .recharts-legend-item-text {
    color: rgb(165, 165, 165)!important;
}

.home-chart-label {
    margin-right: 10px;
}

.chart-label-upper {
    transform: translateY(-20px);
    font-size: 18px;
}

.chart-label-lower {
    transform: translateY(20px);
    font-size: 18px;
    fill: red;
}

.notice-list {
    margin-top: 40px;
    font-size: 16px;
}

.notice-list li {
    margin-bottom: 20px;
}